import AffichageTops from "../Components/AffichageTops";

function Home() {

    return(
        <div id="home" className="flex flex-wrap justify-center mb-16 pt-16 px-6">
            <AffichageTops />
        </div>
    )
}

export default Home;