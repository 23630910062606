import React, { createContext, useContext, useState, useEffect } from 'react';
import { verifAthentification, getUser } from './utils/utilsAPI';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const authenticatedUserInfos = await verifAthentification();
      if (authenticatedUserInfos && typeof authenticatedUserInfos.authenticated === 'boolean') {
        const fullUserData = await getUser(authenticatedUserInfos.userId);
        setUser({ ...authenticatedUserInfos, ...fullUserData });
      } else {
        setUser({ authenticated: false, userId: null }); // Valeurs par défaut en cas d'erreur
      }
    };
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
    {/* <UserContext.Provider value={user}> */}
      {children}
    </UserContext.Provider>
  );
};

// Custom hook pour accéder au contexte
export const useUser = () => useContext(UserContext);
