import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'tailwindcss/tailwind.css';
import { useUser } from '../../UserContext';



const initialDropZones = Array(5).fill(null);

const FavDayForm = ({ croppedImages, getDateFormatted, titre, choix, datas, datasEdit }) => {
  
  // Utilisation de useEffect pour gérer les changements de choix
  const [items, setItems] = useState([]);
  const {user} = useUser();
  
  useEffect(() => {
    if (choix && Array.isArray(choix) && choix.length > 0) {
      // Vérification de la structure de choix
      const validChoices = choix.filter(item => item.id && item.content);
      setDropZones(initialDropZones);
      setItems(validChoices);
    }
  }, [choix]);


  // Fonction pour gérer le bouton POSTER
  const handleSubmit = () => {

    // Récupérer les contenus des dropZones
    const dropZoneContents = dropZones.reduce((acc, zone, index) => {
      acc[`choix${index + 1}`] = zone.content;
      return acc;
    }, {});

    // Création du nouveau favDay
    const newTopFavDay = {
      userId: user.userId,
      choix: dropZoneContents,
      favday: true,
      motCle: {chip1: 'favDay'},
      titre: titre,
      img: croppedImages.smallImg,
    };

    datas(newTopFavDay);


    // Nombre de dropZones
    const totalChoix = dropZones.length;

    const dropZoneCompteur = dropZones.reduce((acc, zone, index) => {
      acc[`choix${index + 1}`] = {
        nom: zone.content,             // Contenu de la dropZone
        valeur: totalChoix - index     // Compteur inversé : commence à 5 et diminue
      };
      return acc;
    }, {});


    // Modification du  favDay
    const editTopFavDay = {
      choix: dropZoneCompteur,
      userID: user.userId
    };

    datasEdit(editTopFavDay);

  };


  const [dropZones, setDropZones] = useState(initialDropZones);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const newItems = Array.from(items);
    const newDropZones = Array.from(dropZones);

    if (source.droppableId === 'items' && destination.droppableId === 'items') {
      const [movedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, movedItem);
      setItems(newItems);
    } else if (source.droppableId.startsWith('dropZone') && destination.droppableId.startsWith('dropZone')) {
      const sourceIndex = parseInt(source.droppableId.split('-')[1], 10);
      const destinationIndex = parseInt(destination.droppableId.split('-')[1], 10);
      const [movedItem] = newDropZones.splice(sourceIndex, 1, dropZones[destinationIndex]);
      newDropZones.splice(destinationIndex, 1, movedItem);
      setDropZones(newDropZones);
    } else if (source.droppableId === 'items' && destination.droppableId.startsWith('dropZone')) {
      const dropZoneIndex = parseInt(destination.droppableId.split('-')[1], 10);
      if (newDropZones[dropZoneIndex]) {
        newItems.push(newDropZones[dropZoneIndex]);
      }
      newDropZones[dropZoneIndex] = newItems[source.index];
      newItems.splice(source.index, 1);
      setItems(newItems);
      setDropZones(newDropZones);
    } else if (source.droppableId.startsWith('dropZone') && destination.droppableId === 'items') {
      const dropZoneIndex = parseInt(source.droppableId.split('-')[1], 10);
      newItems.splice(destination.index, 0, newDropZones[dropZoneIndex]);
      newDropZones[dropZoneIndex] = null;
      setItems(newItems);
      setDropZones(newDropZones);
    }
  };

  return (
    <div className='relative'>
      
      <div style={{ backgroundImage: `url(${croppedImages.bigImg})` }} className='bg-contain relative rounded-2xl mb-10 p-8 px-14 z-0 h-[800px] w-[500px]' >
        <div className="absolute top-0 left-0 w-full h-full bg-purple-500 opacity-50 z-[1] rounded-2xl"></div>

        <div className='relative z-[2]'>
          {/* Header  */}
          <div className='flex flex-row items-center justify-start text-secondary'>
              <svg version="1.1" id="linkFavday" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48.3 40.8" strokeWidth="1.5" className="w-14 h-14" fill="none">
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="1" y1="13.2" x2="47.3" y2="13.2"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="35" y1="7.2" x2="35" y2="1"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="13.3" y1="1" x2="13.3" y2="7.2"></line>
                  <path className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M39.3,39.8h2.1c3.2,0,5.9-2.6,5.9-5.9V10c0-3.2-2.6-5.9-5.9-5.9H6.9C3.6,4.1,1,6.8,1,10v23.9 c0,3.2,2.6,5.9,5.9,5.9h23"></path>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="40.1" y1="31.8" x2="8.1" y2="31.8"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="33.8" y1="25.9" x2="14.5" y2="25.9"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="27.5" y1="20" x2="20.8" y2="20"></line>
              </svg>
              <div className='flex flex-col pl-4'>
                  <h2 className='text-xl'>fav of the day {getDateFormatted}</h2>
                  <h3 className='text-2xl'>{ titre }</h3>
              </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="mt-4 flex flex-col space-y-2">
              {dropZones.map((dropZone, index) => (
                <div key={index} className='flex flex-row items-center justify-start'>
                  <div className='w-[8%] text-secondary text-4xl font-bold'>{index + 1}</div>
                  <Droppable droppableId={`dropZone-${index}`} direction="vertical">
                    {(provided, snapshot) => (
                      <div
                        className={`h-12 flex flex-col space-y-2 items-center justify-center w-[92%] border-2 border-secondary rounded-full border-dashed ${
                          snapshot.isDraggingOver ? "bg-primary opacity-50" : "bg-transparent"
                        }`}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {dropZone && (
                          <Draggable key={dropZone.id} draggableId={dropZone.id} index={index}>
                            {(provided) => (
                              <div
                                className="bg-secondary text-primary h-12 rounded-full cursor-pointer w-full p-2 pl-4" 
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {dropZone.content}
                              </div>
                            )}
                          </Draggable>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </div>
            <div className='flex flex-col items-end my-4'>
                <button type="submit" onClick={handleSubmit} id="buttonSubmit" className="bg-tertiary-100 text-primary rounded-full px-8 py-3">
                    POSTER
                </button>
            </div>
            <p className='text-white mb-4'>Faire glisser ses cinq choix avant de poster</p>
            <Droppable droppableId="items" direction="vertical">
              {(provided) => (
                <div
                  className="flex flex-col items-end space-y-2"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className={`text-primary p-2 pl-4 h-12 cursor-pointer w-[92%] border-secondary border-2 rounded-full ${
                            snapshot.isDragging ? "bg-secondary" : ""
                          }`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default FavDayForm;
