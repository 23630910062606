
import GestionRemixTop from "./GestionRemixTop";
import GestionLike from "./GestionLike";
import GestionCommentaires from "./GestionCommentaires";
import { useUser } from '../UserContext';
import GestionShare from "./GestionShare";


function FooterTop({ topDatas }) {

    const { user } = useUser();

    return(
        <>
            <div className={`flex w-full mt-6 z-10`}>
                
                <GestionShare topDatas={topDatas} currentUserId={user._id} favDay={topDatas.favday}/>
                {(!topDatas.favday && (!topDatas.remixTop || topDatas.remixTop === "false") ) && (
                    <GestionRemixTop currentTopId={topDatas._id} />
                )}
                <GestionLike currentTopId={topDatas._id} currentUserId={user._id} favDay={topDatas.favday}/> 
                <GestionCommentaires currentTopId={topDatas._id} currentUserId={user._id} favDay={topDatas.favday}/>
            </div> 
        </>
      
    )
}

export default FooterTop;