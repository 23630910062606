//import axios from "axios";
import { useEffect } from "react";
import FooterAdmin from "./footer";
import FormFavDayCreateAdmin from "../../Components/FormCreateFavDay";
import axios from "axios";
import HeaderAdmin from "./Header";
import Swal from 'sweetalert2'

function FavDayAdmin() {

  //Settigs des alertes
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false
    })

    useEffect(() => {

        //Gestion des icons du footer
        const footerIconSelector = document.querySelector('#footer > div > div > a:nth-child(3)');
        const selectAllSVGElement = footerIconSelector.querySelectorAll('*');
        selectAllSVGElement.forEach((element) => {
            element.classList.remove('stroke-tertiary-300');
            element.classList.add('stroke-primary');
        });

    }, []);


    //Ajout des nouveaux users dans la base de donnée
    function AddFavDay(data) {
        // Enregistrement du nouvel user account
          axios({
            method: 'post',
            url:   process.env.REACT_APP_BACKEND_URL + '/api/favdaycreate',
            data: data
          }).then((response) => {
            Toast.fire({
              icon: 'success',
              title: "Fav day ajouté",         
            })
          }).catch(({ response }) => { 
            // setMsgCompte(response.data.message);
          })
  
      }

    return(
        <>
            <HeaderAdmin/>
            <FormFavDayCreateAdmin datas={AddFavDay}/>
            <FooterAdmin/>
        </>
    )
    
}
export default FavDayAdmin;