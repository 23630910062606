import { 
    frenchDateConverter,
} from '../utils/utils'; 

import { 
    getTop,
    getAllTops,
    getUser,
} from '../utils/utilsAPI';

import Loader from "../Components/Loader";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
// import { useUser } from '../UserContext';
import FooterTop from './FooterTop';

function AffichageTops({ searchValue, userIdProfil, userIdProfilLike, IDTop, TopOriginal }) {    

    // const {user} = useUser();
    const [loading, setLoading] = useState(true);
    const [infosTops, setInfosTops] = useState([]);

    useEffect(() => {
        
        // Récupération des données du top
        const getAllTopsDatas = async () => {
            try {
                const topsData = IDTop ? await getTop(IDTop) : await getAllTops();                

                // S'assurer que topsData est un tableau
                const topsDataArray = Array.isArray(topsData) ? topsData : [topsData];
                const usersDataPromises = topsDataArray.map(async (item) => {
                    const userData = await getUser(item.userId);
                    let formattedDate = frenchDateConverter( item.date );
                    
                    return {
                        ...item,
                        date: formattedDate.shortDate,
                        user: userData 
                    };
                });

                const combinedData = await Promise.all(usersDataPromises);

                setInfosTops(combinedData);
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
            } finally {
                setLoading(false);
            }
        };
        getAllTopsDatas();
        
         
        
        // eslint-disable-next-line
    }, []);

    // Utilisez la méthode filter() pour filtrer les tops en fonction de la recherche
    let filteredTops = [];
    if (searchValue) {
        filteredTops = infosTops.filter((top) => {
            const searchValueLowerCase = searchValue.toLowerCase();
            const pseudoLowerCase = top.user.pseudo.toLowerCase();
            const titreLowerCase = top.titre.toLowerCase();
            const motCleLowerCase = Object.values(top.motCle).flat().map(keyword => keyword.toLowerCase());

            const pseudoMatches = pseudoLowerCase.includes(searchValueLowerCase);
            const titreMatches = titreLowerCase.includes(searchValueLowerCase);
            const motCleMatches = motCleLowerCase.some(keyword => keyword.includes(searchValueLowerCase));
            return titreMatches || motCleMatches || pseudoMatches;
        });
    }else if(userIdProfil){
        filteredTops = infosTops.filter((top) => {
            const searchValueLowerCase = userIdProfil.toLowerCase();
            const userIdLowerCase = top.userId.toLowerCase();

            const userIdoMatches = userIdLowerCase.includes(searchValueLowerCase);
            return userIdoMatches;
        });
    }else if(userIdProfilLike){
        filteredTops = infosTops.filter((top) => {
            const searchValueLowerCase = userIdProfilLike.toLowerCase();
            const users = top.like.users;
            const userIds = users.map(user => user.toLowerCase());

            return userIds.includes(searchValueLowerCase);
        });
    }else{
        filteredTops = [...infosTops];
    }
        
 

    return( 
        loading ? ( <Loader/> ) : 
            (
            <div id="home" className={`flex flex-wrap justify-center`}>
                {filteredTops
                    .sort((a, b) => {
                        // Fonction pour convertir "DD/MM/YYYY" en objet Date
                        const convertToDate = (dateStr) => {
                            const [day, month, year] = dateStr.split('/');
                            return new Date(`${year}-${month}-${day}`);
                        };
                        // Comparer les dates après conversion
                        return convertToDate(b.date) - convertToDate(a.date);
                    })
                    .map(top => (
                    <div 
                        key={top._id} 
                        style={{ backgroundImage: top.favday && `url(${top.img})` }} 
                        className={`
                            ${top.favday ? 
                                'w-full relative bg-cover border-transparent flex flex-wrap p-6 my-2 rounded-lg text-left justify-start' 
                                : 
                                'w-full border-2  border-secondary flex flex-wrap p-6 my-2 rounded-lg text-left justify-start'
                            }
                            ${TopOriginal ? 'border-b-0 border-r-0 border-l-0 border-t-2 rounded-none border-tertiary-200' : ''}
                        `}
                    >
                        {top.favday && ( <div className={`absolute top-0 left-0 w-full h-full bg-purple-500 opacity-50 rounded-lg`}></div> )}
                        <div className={` ${top.favday ? 'z-10' : ''}`}>
                            {/* User infos */}
                            <NavLink to={`/profil/${top.user.tagName}`}>
                                <div className="flex">
                                    <div>
                                        <img 
                                            className="inline-block min-w-2 w-16 mr-2 rounded-full ring-2 ring-white"
                                            src={top.user.avatar}
                                            alt={`Avatar de ${top.user.pseudo}`}
                                        />
                                    </div>
                                    <div>
                                        {top.favday ? 
                                            (
                                                <>
                                                    <p className="font-semi text-white">{top.user.pseudo}<span className="font-normal"> @{top.user.tagName} / {top.date}</span></p>
                                                    <div className='flex flex-row items-start justify-start text-secondary'>
                                                        <svg version="1.1" id="linkFavday" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48.3 40.8" strokeWidth="1.5" className="w-7 h-7" fill="none">
                                                            <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="1" y1="13.2" x2="47.3" y2="13.2"></line>
                                                            <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="35" y1="7.2" x2="35" y2="1"></line>
                                                            <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="13.3" y1="1" x2="13.3" y2="7.2"></line>
                                                            <path className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M39.3,39.8h2.1c3.2,0,5.9-2.6,5.9-5.9V10c0-3.2-2.6-5.9-5.9-5.9H6.9C3.6,4.1,1,6.8,1,10v23.9 c0,3.2,2.6,5.9,5.9,5.9h23"></path>
                                                            <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="40.1" y1="31.8" x2="8.1" y2="31.8"></line>
                                                            <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="33.8" y1="25.9" x2="14.5" y2="25.9"></line>
                                                            <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="27.5" y1="20" x2="20.8" y2="20"></line>
                                                        </svg>
                                                        <div className='flex flex-col pl-4'>
                                                            <h2 className='text-lg '>{top.titre}</h2>
                                                        </div>
                                                    </div>
                                                </>
                                        
                                            )
                                            :
                                            (
                                                <>
                                                    <p className="font-semi">{top.user.pseudo}<span className="font-normal text-tertiary-300"> @{top.user.tagName} / {top.date}</span></p>
                                                    <p className="text-primary font-bold font-myriad">{top.titre}</p>
                                                </>
                                            )
                                        }
                                        <div className="flex flex-wrap">
                                            {/* Affichage des mots-clés */}
                                            {Array(10).fill(null).map((_, i) => 
                                                top.motCle[`chip${i+1}`] && (
                                                    <p className={`text-sm mt-1 mb-4 ${top.favday ? 'text-white' : 'text-tertiary-300'}`} key={`motCle-${i}`}>
                                                        #{top.motCle[`chip${i+1}`]}&nbsp;
                                                    </p>
                                            ))}
                                        </div>
                                    </div>
                                    
                                


                                </div>
                            </NavLink>
                            {/* Top détaillé */}
                            {/* {!top.favday && (
                                <div className="flex justify-start">
                                    <button type="submit" className="bg-primary text-tertiary-100 rounded-3xl px-6 py-2 m-3 mt-0 ml-0 font-nunito font-bold">Top détaillé</button>
                                </div>
                            )} */}
                            {/* Liste des choix */}
                            <NavLink to={`/top/${top._id}`}>
                                {Array(10).fill(null).map((_, i) => 
                                    top.choix[`choix${i+1}`] && (
                                        <p className={`text-md font-medium ${top.favday ? 'text-white' : 'text-black'}`} key={`choix-${i}`}>
                                            <span className={`font-nunito ${top.favday ? 'text-secondary' : 'text-primary'}`}>
                                                {i+1}&#x2802;
                                            </span>
                                            {top.choix[`choix${i+1}`]}
                                        </p>
                                ))}
                            </NavLink>
                            {/* Footer */}
                            <FooterTop topDatas={top}/>
                            {/* Affichage du top original si c'est un top remixé */}
                            {(top.remixTop && top.remixTop !== "false") && (
                                <>
                                    <p className='italic text-primary mt-6'>Fait à partir de ce top :</p>
                                    <AffichageTops IDTop={top.remixTop} TopOriginal={true}/>
                                </>
                            )}
                        </div>

                    </div>
                ))}
            </div>
            )  
    )
}

export default AffichageTops;