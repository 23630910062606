import { 
    frenchDateConverter,
    Toast 
} from '../utils/utils'; 

import { 
    getFavDay,
    saveTop,
    editFavDay,
} from '../utils/utilsAPI'; 

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import FavDayStats from '../Components/favDay/FavDayStats';
import FavDayForm from '../Components/favDay/FavDayForm';
import Loader from '../Components/Loader';
import { useUser } from '../UserContext';

function FavDay() {

    // initialisation de l'objet navigate
    const navigate = useNavigate(); 
    const [infosFavDay, setInfosFavDay] = useState({});
    const currentDate = frenchDateConverter();
    const [loading, setLoading] = useState(true);
    const {user} = useUser();

    
    useEffect(() => {
        
        // Récupération des données du "fav of the day"
        const getFavDayData = async () => {
            try {
                const data = await getFavDay(currentDate.encodeDate);
                setInfosFavDay(data);
                setLoading(false);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: 'Aucun fav of the day pour le moment'
                });
            }
        };
        getFavDayData();
    
    }, [currentDate, navigate]);

        
    //Sauvegarde du fav of the day dans les tops
    const SaveTopDatas = (datasForm) => {

        saveTop(datasForm)
            .then((response ) => {
                console.log("Succès :", response);
                Toast.fire({
                    icon: 'success',
                    title: 'La fav of the day a été ajoutée à vos tops'
                });
                navigate('/');
            })
            .catch((error) => {
                console.error("Erreur :", error);
                Toast.fire({
                    icon: 'error',
                    title: "La sauvegarde du top n'a pas fonctionné"
                });
            });
    };


    //Ajout de l'utilisateur au favday
    const EditFavDayDatas = (datasFormEdit) => {

        editFavDay(datasFormEdit, user.userId, currentDate)
            .then(() => {})
            .catch((error) => {
                console.log(error); 
            });
    };


    return(
        <>
            {loading ? ( <Loader /> ) : 
            (
                <div className="flex flex-col items-center justify-center pt-20 p-10">
                    {infosFavDay.images && !infosFavDay.users.includes(user.userId) ? (
                        <FavDayForm
                            croppedImages={infosFavDay.images} 
                            getDateFormatted={infosFavDay.date} 
                            titre={infosFavDay.titre} 
                            choix={infosFavDay.choix}
                            users={infosFavDay.listUsers}
                            datas={SaveTopDatas} 
                            datasEdit={EditFavDayDatas} 
                        />
                    ) : (
                        infosFavDay.images && (
                            <FavDayStats 
                                croppedImages={infosFavDay.images} 
                                getDateFormatted={infosFavDay.date} 
                                choix={infosFavDay.choix} 
                                titre={infosFavDay.titre} 
                                id="container-preview-full-faotv" 
                            />
                        )
                    )}
                </div>
            )}
        </>
        
    )
    
}

export default FavDay;