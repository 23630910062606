import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';


const FavDayBuildStats = ({ croppedImages, getDateFormatted, titre, choix }) => {

  // Utilisation de useEffect pour gérer les changements de choix
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    if (choix && Array.isArray(choix) && choix.length > 0) {
      // Vérification de la structure de choix
      const validChoices = choix.filter(item => item.id && item.content);
      setItems(validChoices);
    }
  }, [choix]);


  return(
    <div className='relative'>
      
      <div style={{ backgroundImage: `url(${croppedImages.mediumImg})` }} className='bg-contain relative w-full rounded-2xl mb-10 p-8 px-14 z-0 h-[600px]' >
        <div className="absolute top-0 left-0 w-full h-full bg-purple-500 opacity-50 z-10 rounded-2xl"></div>

        <div className='relative z-20'>
          {/* Header  */}
          <div className='flex flex-row items-center justify-start text-secondary mb-8'>
              <svg version="1.1" id="linkFavday" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48.3 40.8" strokeWidth="2.5" className="w-14 h-14" fill="none">
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="1" y1="13.2" x2="47.3" y2="13.2"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="35" y1="7.2" x2="35" y2="1"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="13.3" y1="1" x2="13.3" y2="7.2"></line>
                  <path className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M39.3,39.8h2.1c3.2,0,5.9-2.6,5.9-5.9V10c0-3.2-2.6-5.9-5.9-5.9H6.9C3.6,4.1,1,6.8,1,10v23.9 c0,3.2,2.6,5.9,5.9,5.9h23"></path>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="40.1" y1="31.8" x2="8.1" y2="31.8"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="33.8" y1="25.9" x2="14.5" y2="25.9"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="27.5" y1="20" x2="20.8" y2="20"></line>
              </svg>
              <div className='flex flex-col pl-4'>
                  <h2 className='text-xl'>fav of the day {getDateFormatted()}</h2>
                  <h3 className='text-2xl'>{ titre }</h3>
              </div>
          </div>
          {/* Liste des choix  */}
          {items.map((item, index) => (
            <div key={index} className='flex flex-row items-center text-xl mb-4'>
              <span className='text-secondary text-6xl'>{ index + 1 }&nbsp;&nbsp;</span>
              <div className='w-full'>
                <p className='list-disc text-white'>{ item.content }</p>
                <div className='flex flex-row items-center'>
                  <div className='bg-secondary w-full h-6 rounded-full mr-4'></div>
                  <p className='text-white text-2xl'>50%</p>
                </div>
              </div>
            </div>
          ))}   
          <div className='p-2.5 flex w-5/6 justify-around mt-6'>
            {/* Share */}
            <div>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 35.9 35.9" fill="none" strokeWidth={2.5} stroke="#ffffff">
                    <g>
                        <ellipse transform="matrix(0.5257 -0.8507 0.8507 0.5257 8.7697 28.1674)" cx="29.6" cy="6.2" rx="5.5" ry="5.5"/>
                        <ellipse transform="matrix(0.5257 -0.8507 0.8507 0.5257 -11.176 39.2879)" cx="29.6" cy="29.7" rx="5.5" ry="5.5"/>
                        <circle cx="6.2" cy="18" r="5.5"/>
                        <line x1="15.4" y1="13" x2="19.9" y2="10.7"/>
                        <line x1="15.4" y1="22.8" x2="19.9" y2="25.1"/>
                    </g>
                </svg>
            </div>
            
            {/* like */}
            <div className="flex items-center">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40.5 35.8" fill="none" strokeWidth={2.5}  className="cursor-pointer w-8 h-8 stroke-white">
                    <path d="M20.2,35l15.9-16.3c2.2-1.9,3.6-4.7,3.6-7.8c0-5.6-4.4-10.1-9.9-10.1c-4.7,0-8.6,3.4-9.6,7.9
                    c-1-4.5-4.9-7.8-9.6-7.8c-5.4,0-9.9,4.5-9.9,10.1c0,3.1,1.3,5.8,3.5,7.7l10.9,11.2"/>
                </svg>&nbsp;<span className="cursor-pointer text-sm text-white">0</span>
            </div>
            
            {/* commentaires */}
            <div className="flex items-center">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 36.1 36.6" fill="none" strokeWidth={2.5}  className="w-8 h-8">
                    <path stroke="#ffffff" d="M4,28.1l-2.5,7.8l7.7-2.9c2.6,1.5,5.6,2.4,8.8,2.4c9.6,0,17.3-7.8,17.3-17.3c0-9.6-7.8-17.3-17.3-17.3
                    C8.5,0.8,0.8,8.5,0.8,18.1c0,0.8,0.1,1.7,0.2,2.5"/>
                </svg>&nbsp;<span className="text-sm text-white">1</span>
            </div>


        </div>       
        </div>
      </div>
    </div>
  )

  
};

export default FavDayBuildStats;
