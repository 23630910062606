import { useForm } from "react-hook-form";
import {  useState } from "react";

import RechercheGestionAbo from "../Components/RechercheGestionAbo";
import AffichageTops from "../Components/AffichageTops";
import { useUser } from "../UserContext";

function Recherche() {
     
    //Mise en place de la gestion du form avec useForm
    const {register, reset} = useForm();
    // Ajoutez un état local pour stocker la valeur de recherche
    const [searchValue, setSearchValue] = useState("");
    // on vérifie l'était du filtre personnes
    const [filterPersonneState, setfilterPersonneState] = useState(true);
    // on vérifie l'état du filtre top
    const [filterPostsState, setfilterTopsState] = useState(false);
    const { user } = useUser();

    function FilterUsers() {
        setfilterPersonneState(true); 
        setfilterTopsState(false);
    }
      
    function FilterTops() {
        setfilterPersonneState(false);
        setfilterTopsState(true);
    }

    return(
        <>
            <div id="recherche" className="mb-16 mt-12 pt-2">
               
                <form>
                    <div className='mt-5 flex flex-col px-20'>
                        <div className="relative mt-2 rounded-md">
                            <input 
                                {...register('recherche')}  
                                type="text"
                                name="recherche" 
                                id="recherche"
                                placeholder="Recherche..."
                                autoComplete='off' 
                                className="w-full text-tertiary-400 border-2 border-primary rounded-full placeholder:text-sm placeholder:text-tertiary-300 pl-2 focus:outline-none"
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center">
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    fill="none" viewBox="0 0 24 24" 
                                    strokeWidth={1.5} 
                                    className="w-5 h-5 stroke-tertiary-300 mr-2"
                                    onClick={(e) => {
                                        setSearchValue("");
                                        reset();
                                    }}
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </form>
         
            </div>
            <div id="recherchePostPersonne" className="grid grid-cols-2 px-16 text-center">
                <div 
                    id="prersonnes" 
                    className={filterPersonneState ? "cursor-pointer border-tertiary-300" : "cursor-pointer border-secondary"}
                    onClick={FilterUsers}
                >
                    <h1 
                        className={filterPersonneState ? "font-bold text-secondary" : " font-bold animate-text text-tertiary-300 "}
                    >PERSONNES</h1>
                </div> 
                <div 
                    id="posts"
                    className="cursor-pointer"
                    onClick={FilterTops}
                >
                    <h1 
                        className={filterPersonneState ? "font-bold text-tertiary-300" : "font-bold animate-text  text-secondary"}
                    >POSTS</h1>
                </div>
                <div> 
                    <hr id="slider" 
                        className={filterPersonneState ? "slide-in border-2 border-secondary" : "slide-in border-2 border-secondary active"}
                    ></hr>
                </div>
                
            </div>
            
            {filterPersonneState && <RechercheGestionAbo searchValue={searchValue}/>} 
            {filterPostsState && 
                <div id="home" className="flex flex-wrap justify-center mb-16 pt-10 px-6">
                    <AffichageTops userId={user.userId} searchValue={searchValue}/>
                </div>
            }
        </>
    )
}

export default Recherche;