import { useState } from 'react';
import axios from "axios";

import Formlogin from '../Components/Formlogin';
import { useNavigate } from 'react-router-dom';


function Login() {

  //Gestion du useState de vérification si le compte existe ou non
  const [UserAccountisOK, setUserAccountisOK] = useState(false);
  //Gestion du message d'erreur
  const [MsgCompte, setMsgCompte] = useState('');
  // initialisation de l'objet navigate
  const navigate = useNavigate();  
  // const { userDonne, setUserDonne } = useContext(UserContext);


  //Gestion de la connexion user
    function loginConnect(data) {

      const InfoUser = {
        email: data.email,
        password: data.password
      };
            
      axios({
          method: 'post',
          url:   process.env.REACT_APP_BACKEND_URL + '/api/login',
          data: InfoUser,
          withCredentials: true
        }).then(function (response) {
          
            //Je mets également ces donnée dans le localStorage en cas de rafraichissement de la page 
            localStorage.setItem('userData', JSON.stringify(response.data));

            setUserAccountisOK(true);
            setMsgCompte('');
            navigate('/loader');
        }).catch(() => { 
            setUserAccountisOK(false);
            setMsgCompte('Aucun compte associé');
        }); 

    }


  return (

    <Formlogin 
      datas={loginConnect}
      isExistAccount={UserAccountisOK}
      MsgCompte={MsgCompte} 
    /> 

  );
}

export default Login;