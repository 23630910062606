import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ImageCropDialog from './Crop/ImageCropDialog';
import FavDayBuildMain from './FavDayBuildMain';
import FavDayBuildDone from './FavDayBuildDone';
import FavDayBuildStats from './FavDayBuildStats';

function FormFavDayCreateAdmin({ datas }) {
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const [fileData, setFileData] = useState({ bigImg: null, smallImg: null, mediumImg: null });
  const [selectedImages, setSelectedImages] = useState({ smallImg: null, bigImg: null, mediumImg: null });
  const [croppedImages, setCroppedImages] = useState({ smallImg: null, bigImg: null, mediumImg: null });
  const [isVisible, setIsVisible] = useState();
  const [choicesList, setChoicesList] = useState([]);
  const aspectRatios = [{ value: 5/8, text:"bigImg" }, { value: 5/6, text:"mediumImg" }, { value: 10/9, text:"smallImg" }];


  const convertBlobToBase64 = (blobUrl) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function() {
        reject(new Error('Failed to convert blob to base64'));
      };
      xhr.open('GET', blobUrl);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };


  const onSubmit = async (data) => {
    try {

      const bigImgBase64 = await convertBlobToBase64(fileData.bigImg);
      const mediumImgBase64 = await convertBlobToBase64(fileData.mediumImg);
      const smallImgBase64 = await convertBlobToBase64(fileData.smallImg);

      const newFavDay = {
        titreFavday: data.titreFavday,
        fav1: data.fav1,
        fav2: data.fav2,
        fav3: data.fav3,
        fav4: data.fav4,
        fav5: data.fav5,
        bigImg: bigImgBase64,
        mediumImg: mediumImgBase64,
        smallImg: smallImgBase64,
        creationDate: getDateFormatted()
      };
      
      datas(newFavDay);
    } catch (error) {
      console.error('Error converting blob to base64', error);
    }
  };

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Image = reader.result;
      setSelectedImages(prevState => ({ ...prevState, [fileType]: base64Image }));
    };
    reader.readAsDataURL(file);
  };

  const setCroppedImageFor = (id, crop, zoom, aspect, croppedImageUrl) => {
    const type = aspectRatios.find(ratio => ratio.value === aspect.value)?.text;
    if (type) {
      setCroppedImages(prevState => ({ ...prevState, [type]: croppedImageUrl }));
      setFileData(prevState => ({ ...prevState, [type]: croppedImageUrl }));
      setSelectedImages({ smallImg: null, bigImg: null, mediumImg: null });
    }
  };

  const onCancel = () => {
    setSelectedImages({ smallImg: null, bigImg: null, mediumImg: null });
  };

  const getDateFormatted = () => {
    const today = new Date();
    return `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
  };

  const choicesListSelected = (size) => {
    const formData = getValues();
    const newChoicesList = [
      { id: "item-1", name: "fav1", content: formData.fav1 },
      { id: "item-2", name: "fav2", content: formData.fav2 },
      { id: "item-3", name: "fav3", content: formData.fav3 },
      { id: "item-4", name: "fav4", content: formData.fav4 },
      { id: "item-5", name: "fav5", content: formData.fav5 },
    ];
    setChoicesList(newChoicesList);
    setIsVisible(size);
  };

  const getSelectedImageDetails = () => {
    for (const [key, value] of Object.entries(selectedImages)) {
      if (value) {
        return { id: key, imageUrl: value, aspectInit: aspectRatios.find(ratio => ratio.text === key) };
      }
    }
    return {};
  };

  const { id, imageUrl, aspectInit } = getSelectedImageDetails();



  return (
    <>
        <h2 className='text-left font-bold text-2xl leading-5 p-10 pb-4'>Création du Fav of the Day</h2>
        <div className='flex flex-col items-center justify-center p-10'>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
                <div className='mb-10'>
                    <label htmlFor="titreFavday" className="block text-sm text-tertiary-300 font-bold text-left">
                    Titre du fav of the day
                    </label>
                    <input
                    type="text"
                    name="titreFavday"
                    id="titreFavday"
                    autoComplete='off'
                    className="text-primary border-b border-tertiary-300 w-full focus:outline-none font-bold"
                    {...register("titreFavday", { required: "Il faut remplir le titre du fav of the Day" })}
                    />
                    <p className="text-red-500">{errors.titreFavday && errors.titreFavday.message}</p>
                </div>
                {[1, 2, 3, 4, 5].map(num => (
                    <div className='mb-8 flex flex-row items-center' key={num}>
                    <label htmlFor={`fav${num}`} className="block text-xl text-secondary font-bold text-left pr-2">{num}</label>
                    <input
                        type="text"
                        name={`fav${num}`}
                        id={`fav${num}`}
                        autoComplete='off'
                        className="text-primary border-b border-tertiary-300 w-full focus:outline-none font-bold"
                        {...register(`fav${num}`, { required: "Il faut remplir le champ" })}
                    />
                    <p className="text-red-500">{errors[`fav${num}`] && errors[`fav${num}`].message}</p>
                    </div>
                ))}
                <div className='flex flex-row justify-between'>
                    {['big', 'medium', 'small'].map(size => (
                        <div className="w-1/4 h-full" key={size}>
                            <label htmlFor={`img${size.charAt(0).toUpperCase() + size.slice(1)}`} className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                </svg>
                                <p className="text-xs text-gray-500 dark:text-gray-400">{size}</p>
                                </div>
                                <input
                                id={`img${size.charAt(0).toUpperCase() + size.slice(1)}`}
                                type="file"
                                className="hidden"
                                name={`img${size.charAt(0).toUpperCase() + size.slice(1)}`}
                                onChange={(event) => handleFileChange(event, `${size}Img`)}
                                />
                            </label>
                            <button 
                                type="button" 
                                id="buttonPreview" 
                                className="bg-primary text-tertiary-100 px-6 py-2 mb-5 mt-2 w-full rounded-lg"
                                onClick={() => choicesListSelected(size)} 
                            >
                                Preview
                            </button>
                        </div>
                    ))}
                </div>
                {imageUrl && (
                    <ImageCropDialog
                      id={id}
                      imageUrl={imageUrl}
                      cropInit={{ x: 0, y: 0 }}
                      zoomInit={1}
                      aspectInit={aspectInit}
                      setCroppedImageFor={setCroppedImageFor}
                      onCancel={onCancel}
                    />
                )}
                {/* Preview du fav of the day */}
                {isVisible === "small" && (
                  <FavDayBuildDone 
                      croppedImages={croppedImages} 
                      getDateFormatted={getDateFormatted} 
                      choix={choicesList} 
                      titre={getValues('titreFavday')} 
                      id="container-preview-full-faotv" 
                  />
                )}
                {isVisible === "medium" && (
                  <FavDayBuildStats 
                    croppedImages={croppedImages}  
                    getDateFormatted={getDateFormatted} 
                    choix={choicesList} 
                    titre={getValues('titreFavday')} 
                    id="container-preview-full-faotv" 
                  />
                )}
                {isVisible === "big" && (
                  <FavDayBuildMain 
                      croppedImages={croppedImages}
                      getDateFormatted={getDateFormatted} 
                      choix={choicesList} 
                      titre={getValues('titreFavday')} 
                      id="container-preview-full-faotv" 
                  />
                )}
                {/* Bouton qui sauvegarde le fav  */}
                <div className='mt-8 flex flex-col items-center'>
                    <button 
                        type="submit" 
                        id="buttonPreview" 
                        className="bg-primary text-tertiary-100 rounded-full px-6 py-2 mb-5 w-full"
                    >
                        SAUVEGARDER
                    </button>
                </div>
            </form>
        </div>
    </>
  );
}

export default FormFavDayCreateAdmin;
