import axios from 'axios';


/********************************/
/* REQUETES VERS LA BASE DE USERS */
/********************************/

//Verification si l'utilisateur en cours est connecté
export const verifAthentification = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/api/auth/verifyToken', { withCredentials: true });       
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la vérification:', error.response?.data?.message || error.message);
        return false;
    }
};

//Récupération d'un seul user avec son ID
export const getUser = async (IDUser) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/${IDUser}`);
        return response.data[0];
    } catch (error) {
        console.error(error);
        throw error;
    }
};


//Récupération d'un seul user avec son ID
export const userLogout = async () => {
    try {
        // Envoi de la requête de déconnexion au serveur
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/logout`, {}, { withCredentials: true });

        // Supprimer les données locales du navigateur
        localStorage.removeItem('userData');

    } catch (error) {
        console.error("Erreur lors de la déconnexion:", error);
        throw error;
    }
};






/********************************/
/* REQUETES VERS LA BASE DE TOP */
/********************************/

//Récupération d'un seul top avec son ID
export const getTop = async (IDTop) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/tops/${IDTop}`);
        return response.data[0];
    } catch (error) {
        console.error(error);
        throw error;
    }
};


//Récupération de tous les tops
export const getAllTops = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/tops`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};



//Sauvegarde du top
export function saveTop(datasForm, userId) {
    
    return axios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + '/api/tops',
        data: datasForm,
        userId: userId
    });
}
  
//Récupération de tous les commentaires
export function RecupCommsTop(IDTop) {
    
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url:   process.env.REACT_APP_BACKEND_URL + `/api/commentaires/${IDTop}`,
        }).then((response) => {
            resolve(response);
        }).catch((error) => { 
            console.error(error);
        }); 
    });
}


//Sauvegarde du commentaire
export function saveComm(datasNewComment, IDTop) {
    
    return axios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + `/api/commentaires/${IDTop}`,
        data: datasNewComment,
    });
}


/********************************/
/* REQUETES VERS LA BASE DE FAVDAY */
/********************************/

//Récupération du fav Of the day du jour
export const getFavDay = async (currentDate) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/favday/${currentDate}`);
        const choixData = response.data.choix;
        const choix = [
            { id: "item-1", name: "fav1", content: choixData.choix1.nom, compteur: choixData.choix1.valeur },
            { id: "item-2", name: "fav2", content: choixData.choix2.nom, compteur: choixData.choix2.valeur },
            { id: "item-3", name: "fav3", content: choixData.choix3.nom, compteur: choixData.choix3.valeur },
            { id: "item-4", name: "fav4", content: choixData.choix4.nom, compteur: choixData.choix4.valeur },
            { id: "item-5", name: "fav5", content: choixData.choix5.nom, compteur: choixData.choix5.valeur },
        ];
        return {
            ...response.data,
            choix,
        };
    } catch (error) {
        console.error(error.response.data.message);
        throw error;
    }
};

//Ajout de l'utilisateur au favday
export function editFavDay(datasFormEdit, userId, currentDate) {
    return axios({
        method: 'post',
        url:   process.env.REACT_APP_BACKEND_URL + `/api/editFavDay/${currentDate}`,
        data: datasFormEdit,
        userId: userId
    });
}
  







