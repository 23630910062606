import { 
    getTop,
} from '../utils/utilsAPI';

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function GestionCommentaires({ currentTopId, currentUserId, favDay }) {
    

    //stockage local commentaires
    const [comms, setComms] = useState({});
    //stockage local commentaires
    const [commed, setCommed] = useState(false);

    useEffect(() => {

        // Récupération des commentaires du top
        const getTopComm = async () => {
            try {
                const topsData =  await getTop(currentTopId);

                // MAJ du usetate des commmentaires
                setComms(topsData.commentaires);
                // Chercher si l'utilisateur est dans les commentaires
                setCommed(topsData.commentaires.some(comment => comment.IDUser === currentUserId));
                
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
            } 
        };
        getTopComm();


    },[currentTopId, currentUserId]);

    return(

        <NavLink className="flex" to={`/top/${currentTopId}`}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 36.1 36.6" 
                fill="none" 
                strokeWidth={1.5} 
                className={`
                    w-6 h-6 cursor-pointer  
                    ${favDay ? 
                        (commed ? 'stroke-white' : 'stroke-white') : 
                        (commed ? 'stroke-primary' : 'stroke-tertiary-400')
                    }
                `}
            >
                <path 
                    d="M4,28.1l-2.5,7.8l7.7-2.9c2.6,1.5,5.6,2.4,8.8,2.4c9.6,0,17.3-7.8,17.3-17.3c0-9.6-7.8-17.3-17.3-17.3
                        C8.5,0.8,0.8,8.5,0.8,18.1c0,0.8,0.1,1.7,0.2,2.5"
                />
            </svg>&nbsp;
            <span 
                className={`
                    w-6 h-6 text-sm cursor-pointer 
                    ${favDay ?
                        (commed ? 'text-white' : 'text-white') :
                        (commed ? 'text-primary' : 'text-tertiary-400')
                    }
                `}
            >
                {comms.length}
            </span>

        </NavLink>
    
    )
}
export default GestionCommentaires;