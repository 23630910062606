import './styles/output.css';
import './styles/globalCSS.css';

import { BrowserRouter, Routes, Route  } from 'react-router-dom';

import LoginCreate from './Pages/loginCreate';
import Login from './Pages/login';
import LoaderStart from './Pages/loader';
import TopCreate from './Pages/topCreate';
import Home from './Pages/home'; 
import Recherche from './Pages/recherche';
import Profil from './Pages/profil';
import UsersAdmin from './Pages/admin/users';
import FavDayAdmin from './Pages/admin/favDay';
import Top from './Pages/top';
import ProfilEdit from './Pages/profilEdit';
import FavDay from './Pages/favDay';
import ProtectedRoute from './Components/ProtectedRoutes';
import Header from './Components/Header';
import { UserProvider } from './UserContext';
import Footer from './Components/Footer';


function App() {

return (
    <div className="App mx-auto max-w-xl bg-tertiary-100 h-auto min-h-screen">
      <UserProvider>
        <BrowserRouter>
          {/* Header global */}
          <Header/>

          <Routes>

            {/* Route publiques} */}
            <Route path='/' element={<Home/>}/>
            <Route path='/top/:topId' element={<Top/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/loader' element={<LoaderStart/>}/>
            <Route path='/logincreate' element={<LoginCreate/>}/>

            {/* Route protégées} */}
            <Route
                path="/recherche"
                element={
                  // <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ProtectedRoute>
                      <Recherche />
                  </ProtectedRoute>
                }
            />
            <Route
                path="/profil/:tagName"
                element={
                  <ProtectedRoute>
                      <Profil />
                  </ProtectedRoute>
                }
            />
            <Route
                path="/profiledit"
                element={
                  <ProtectedRoute>
                      <ProfilEdit />
                  </ProtectedRoute>
                }
            />
            <Route
                path="/favday"
                element={
                  <ProtectedRoute>
                      <FavDay />
                  </ProtectedRoute>
                }
            />
            <Route
                path="/admin/users"
                element={
                  <ProtectedRoute>
                      <UsersAdmin />
                  </ProtectedRoute>
                }
            />
            <Route
                path="/admin/favday"
                element={
                  <ProtectedRoute>
                      <FavDayAdmin />
                  </ProtectedRoute>
                }
            />
            <Route
                path="/topcreate"
                element={
                  <ProtectedRoute>
                      <TopCreate />
                  </ProtectedRoute>
                }
            />
            <Route
                path="/topcreate/:topId"
                element={
                  <ProtectedRoute>
                      <TopCreate />
                  </ProtectedRoute>
                }
            />
          </Routes>

          {/* Footer global */}
          <Footer/>

        </BrowserRouter>
      </UserProvider>
    </div>
  );
}

export default App;
