

// import { useState } from "react";
import { NavLink } from "react-router-dom";

function GestionRemixTop({ currentTopId }) {
    

    //stockage local commentaires
    // const [remix, setRemix] = useState({});

    return(

        <NavLink className="flex pr-4" to={`/topcreate/${currentTopId}`}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 36.7 36.7" 
                fill="none" 
                strokeWidth={1.5} 
                className={`w-6 h-6 cursor-pointer`}
            >
                <g>
                     <path fill="#1D1D1B" d="M36.7,18.3c0,10.1-8.2,18.3-18.3,18.3C8.2,36.7,0,28.5,0,18.3C0,8.2,8.2,0,18.3,0C28.5,0,36.7,8.2,36.7,18.3"
                         />
                     <path fill="#FFFFFF" d="M0.6,22.9C0.8,24,1.2,25,1.7,26H35c0.5-1,0.8-2,1.1-3.1H0.6z"/>
                     <path fill="#FFFFFF" d="M15,12.3h6.7c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.7-0.4-1.2-0.4
                         H15c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.4,0.7-0.4,1.2c0,0.5,0.1,0.9,0.4,1.1C14,12.2,14.4,12.3,15,12.3"/>
                     <path fill="#FFFFFF" d="M29,16H7.6c-0.5,0-0.9,0.1-1.2,0.4C6.1,16.7,6,17.1,6,17.6c0,0.5,0.1,0.9,0.4,1.1c0.3,0.3,0.7,0.4,1.2,0.4H29
                         c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.4-1.2C30,16.2,29.6,16,29,16"/>
                </g>
            </svg>&nbsp;
            {/* <span className={`w-6 h-6 text-sm cursor-pointer`}>{remix.length}</span> */}

        </NavLink>
    
    )
}
export default GestionRemixTop;