import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function FormChips({chipData, setChipData, remixTop}) {

  //Gestion du formulaire
  const { register, handleSubmit, reset } = useForm();

  //Suppression des chips
  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  //Ajout des mot clés dans le usetate chipData
  const addChip = function (datas) {
    if (datas.chip) {
      const lengthChoix = chipData.length+1;
      if (lengthChoix <= 5) {
        const newChip = {
            key: `chip${chipData.length + 1}`,
            label: datas.chip,
        };
        const addChip = [...chipData, newChip];
        setChipData(addChip);
        reset();
      }
    }
  }

  return (
    <>
        {!remixTop 
          ? (
            <Form onSubmit={handleSubmit(addChip)}>
                
                <div className='mt-4'>
                    <label htmlFor="chip" className="block text-sm text-tertiary-300 font-bold text-left">
                      Mot clés / #
                    </label>
                    <div className='flex justify-between'>
                      <input
                        type="text"
                        name="chip"
                        id="chip"
                        autoComplete='off'
                        className="text-primary border-b border-tertiary-300 focus:outline-none font-bold w-5/6"
                        {...register('chip')}
                      />
                      <button type="submit" >
                        <svg version="1.1" x="0px" y="0px" viewBox="0 0 48.7 48.7" className="stroke-primary w-8 h-8"><g>
                          <path className="fill-primary stroke-primary" d="M10.7,22.2H38c1.2,0,2.1,1,2.1,2.1c0,1.2-1,2.1-2.1,2.1H10.7c-1.2,0-2.1-1-2.1-2.1  C8.5,23.2,9.5,22.2,10.7,22.2"></path>
                          <path className="fill-primary stroke-primary" d="M22.2,38V10.7c0-1.2,1-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1V38c0,1.2-1,2.1-2.1,2.1C23.2,40.2,22.2,39.2,22.2,38"></path><path className="stroke-primary fill-none" d="M24.4,0.5c13.2,0,23.9,10.7,23.9,23.9c0,13.2-10.7,23.9-23.9,23.9c-13.2,0-23.9-10.7-23.9-23.9 C0.5,11.2,11.2,0.5,24.4,0.5z"></path></g>
                        </svg>
                      </button>
                    </div>
                </div>
                
            </Form>
          ) : (
            <span className='block text-sm text-tertiary-300 font-bold text-left'>
              Mot clés / #
            </span>
          )
        }

        <Paper
          sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              boxShadow: 'none',
              listStyle: 'none',
              pt: 1,
              m: 0,
          }}
          component="ul"
          className='flex-wrap mb-0'
          >
          {chipData.map((data) => {
              let icon;
              return (
                  <ListItem key={data.key}
                    sx={{
                      mb: 1,
                    }}
                  >
                      <Chip
                        icon={icon}
                        label={data.label}
                        {...( (!remixTop || remixTop === "false" ) && { onDelete: handleDelete(data) })}
                      />
                  </ListItem> 
              );
          })}
        </Paper>
        
    </>
  );
}

export default FormChips;