import { useUser } from '../UserContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  if (!user) {
    return <div>Loading...</div>; 
  }
  return user.authenticated ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
