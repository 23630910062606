import { 
    Toast 
} from '../utils/utils'; 

import {
    getTop,
    saveTop,
} from '../utils/utilsAPI'; 


import FormTopCreate from '../Components/FormTopCreate';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import { useEffect, useState } from 'react';

function TopCreate() {

    // initialisation de l'objet navigate
    const navigate = useNavigate(); 
    const { topId } = useParams();  
    const [remixTop, setRemixTop] = useState();

    //Sauvegarde du fav of the day dans les tops
    const SaveTopDatas = (datasForm) => {
        console.log(datasForm);
        saveTop(datasForm )
        
        
            .then(() => {
                Toast.fire({
                    icon: 'success',
                    title: 'Le nouvveau top a été ajouté'
                });
                navigate('/');
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: "La sauvegarde du top n'a pas fonctionné"
                });
                console.error(error);
            });
    };

    // Récupération des infos du top
    useEffect(() => {
    
        if (topId) {
            const getTopDatas = async () => {
                try {
                    const topsData =  await getTop(topId);
                    setRemixTop(topsData);
                    
                } catch (error) {
                    console.error("Erreur lors de la récupération des données :", error);
                } 
            };
            getTopDatas();
        }

    },[]);
    
    


    return(
        <>
            <FormTopCreate datas={SaveTopDatas} remixTop={remixTop}/>
        </>
        
    )
    
}

export default TopCreate;