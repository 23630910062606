import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LoaderStart() {

    // initialisation de l'objet navigate
    const navigate = useNavigate(); 

    useEffect(() => {

        setTimeout(() => {
            
            navigate('/');
            window.location.reload();
        }, 2000);
            
    }, [navigate]);


    return(
        <div className="bg-primary h-screen flex justify-center p-16">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 351.2 124">
            <g>
                <path className="fill-tertiary-100" d="M46.3,63.4c-1.4,0-2.5,0.4-3.2,1.1s-1.1,1.9-1.1,3.3v21.7H8.7V67.9c0-1.5-0.4-2.6-1.1-3.3s-1.8-1.1-3.2-1.1
                    c-1.4,0-2.5,0.4-3.2,1.1S0,66.4,0,67.9v51.5c0,1.4,0.4,2.5,1.1,3.3c0.8,0.8,1.8,1.2,3.2,1.2c1.5,0,2.5-0.4,3.3-1.2
                    c0.7-0.8,1.1-1.9,1.1-3.3V96.9h33.2v22.5c0,1.4,0.4,2.5,1.1,3.3c0.8,0.8,1.8,1.2,3.2,1.2c1.4,0,2.5-0.4,3.2-1.2
                    c0.7-0.8,1.1-1.9,1.1-3.3V67.9c0-1.5-0.4-2.6-1.1-3.3C48.7,63.8,47.7,63.4,46.3,63.4"/>
                <path className="fill-tertiary-100" d="M67.7,81.8c-1.3,0-2.4,0.4-3.1,1.2c-0.7,0.8-1.1,1.9-1.1,3.4v32.8c0,1.5,0.4,2.6,1.1,3.4
                    c0.7,0.8,1.8,1.2,3.1,1.2c1.4,0,2.5-0.4,3.2-1.2c0.7-0.8,1.1-1.9,1.1-3.4V86.4c0-1.5-0.4-2.6-1.1-3.4C70.1,82.2,69,81.8,67.7,81.8"
                    />
                <path className="fill-tertiary-100" d="M129.3,91.9h-14.5c-1.1,0-2,0.3-2.6,0.8c-0.6,0.5-1,1.3-1,2.4c0,1.1,0.3,1.9,1,2.5c0.6,0.6,1.5,0.9,2.6,0.9
                    h10.8v15.9c-1.7,0.6-3.4,1.1-5.3,1.4c-2.4,0.5-4.9,0.7-7.4,0.7c-6.9,0-12.2-1.9-15.7-5.8c-3.5-3.9-5.3-9.5-5.3-17
                    c0-4.8,0.8-8.9,2.3-12.3c1.5-3.4,3.8-6,6.8-7.8c3-1.8,6.7-2.7,11-2.7c2.9,0,5.6,0.4,8,1.1c2.4,0.7,4.7,1.8,6.9,3.3
                    c0.9,0.6,1.7,0.8,2.5,0.7c0.8-0.1,1.4-0.4,1.9-1c0.5-0.6,0.9-1.2,1.1-2c0.2-0.8,0.2-1.5,0-2.2c-0.2-0.7-0.7-1.4-1.5-1.9
                    c-2.7-2-5.7-3.4-8.9-4.2c-3.2-0.9-6.6-1.3-9.9-1.3c-4.6,0-8.7,0.7-12.3,2.1c-3.6,1.4-6.7,3.4-9.2,6.1c-2.5,2.7-4.4,5.9-5.7,9.6
                    c-1.3,3.7-2,7.9-2,12.6c0,6.3,1.1,11.7,3.4,16.2c2.3,4.5,5.6,8,10,10.5c4.4,2.4,9.9,3.7,16.3,3.7c3.1,0,6.2-0.3,9.5-0.9
                    c3.2-0.6,6.1-1.4,8.5-2.3c0.8-0.3,1.4-0.9,1.8-1.6c0.4-0.7,0.5-1.6,0.5-2.6V95.5c0-1.2-0.3-2.1-0.9-2.7
                    C131.3,92.2,130.5,91.9,129.3,91.9"/>
                <path className="fill-tertiary-100" d="M192.5,63.4c-1.4,0-2.5,0.4-3.2,1.1c-0.8,0.8-1.1,1.9-1.1,3.3v21.7H155V67.9c0-1.5-0.4-2.6-1.1-3.3
                    c-0.8-0.8-1.8-1.1-3.2-1.1c-1.4,0-2.5,0.4-3.2,1.1c-0.8,0.8-1.1,1.9-1.1,3.3v51.5c0,1.4,0.4,2.5,1.1,3.3c0.8,0.8,1.8,1.2,3.2,1.2
                    c1.5,0,2.5-0.4,3.3-1.2c0.7-0.8,1.1-1.9,1.1-3.3V96.9h33.2v22.5c0,1.4,0.4,2.5,1.1,3.3c0.8,0.8,1.8,1.2,3.2,1.2
                    c1.4,0,2.5-0.4,3.2-1.2c0.7-0.8,1.1-1.9,1.1-3.3V67.9c0-1.5-0.4-2.6-1.1-3.3C195,63.8,193.9,63.4,192.5,63.4"/>
                <path className="fill-tertiary-100" d="M245.3,71.1c1.2,0,2.1-0.3,2.7-0.9c0.6-0.6,0.9-1.5,0.9-2.7c0-1.1-0.3-2-0.9-2.6c-0.6-0.6-1.5-0.9-2.7-0.9
                    h-29.9c-1.5,0-2.7,0.4-3.5,1.2c-0.8,0.8-1.2,2-1.2,3.5v50.5c0,1.5,0.4,2.7,1.2,3.4c0.8,0.8,1.9,1.2,3.4,1.2c2.7,0,4.1-1.5,4.1-4.6
                    V97h24.2c1.1,0,2-0.3,2.6-0.9c0.6-0.6,1-1.5,1-2.7c0-1.1-0.3-2-1-2.6c-0.6-0.6-1.5-0.9-2.6-0.9h-24.2V71.1H245.3z"/>
                <path className="fill-tertiary-100" d="M351.1,66.1c-0.2-0.8-0.6-1.5-1.2-2c-0.6-0.5-1.5-0.7-2.5-0.7c-1.2,0-2.2,0.3-2.9,0.8
                    c-0.7,0.6-1.3,1.4-1.8,2.4l-19.5,45.5l-19.6-45.4c-0.4-1.1-1-2-1.7-2.5c-0.7-0.6-1.7-0.8-2.9-0.8c-1,0-1.9,0.2-2.6,0.7
                    c-0.7,0.5-1.2,1.1-1.4,2c-0.2,0.8-0.1,1.8,0.3,3l22.6,51.2c0.5,1.2,1.2,2.1,2,2.6c0.8,0.6,1.8,0.9,3.1,0.9c1.3,0,2.3-0.3,3.1-0.9
                    c0.8-0.6,1.4-1.5,1.9-2.6l22.5-51.2C351.1,68,351.3,67,351.1,66.1"/>
                <path className="fill-tertiary-100" d="M247,121.1c0.2,0.8,0.6,1.5,1.2,2c0.6,0.5,1.5,0.7,2.5,0.7c1.2,0,2.2-0.3,2.9-0.8c0.7-0.6,1.3-1.4,1.8-2.4
                    L274.8,75l19.6,45.4c0.4,1.1,1,2,1.7,2.5c0.7,0.6,1.7,0.8,2.9,0.8c1,0,1.9-0.2,2.6-0.7c0.7-0.5,1.2-1.1,1.4-2
                    c0.2-0.8,0.1-1.8-0.3-3L280.1,67c-0.5-1.2-1.2-2.1-2-2.6c-0.8-0.6-1.8-0.9-3.1-0.9c-1.3,0-2.3,0.3-3.1,0.9
                    c-0.8,0.6-1.4,1.5-1.9,2.6l-22.5,51.2C247,119.3,246.8,120.3,247,121.1"/>
                <path className="fill-secondary" d="M70.7,64.7c1.7,1.7,1.7,4.3,0,6c-1.7,1.7-4.3,1.7-6,0c-1.7-1.7-1.7-4.3,0-6C66.4,63,69.1,63,70.7,64.7"/>
                <path className="fill-secondary" d="M121.8,41.8c0,1.3,0.4,2.4,1.2,3.1c0.8,0.7,1.9,1.1,3.4,1.1h98.4c1.5,0,2.6-0.4,3.4-1.1
                    c0.8-0.7,1.2-1.8,1.2-3.1c0-1.4-0.4-2.5-1.2-3.2c-0.8-0.7-1.9-1.1-3.4-1.1h-98.4c-1.5,0-2.6,0.4-3.4,1.1
                    C122.2,39.4,121.8,40.4,121.8,41.8"/>
                <path className="fill-secondary" d="M161.8,4.3c0,1.3,0.4,2.4,1.2,3.1c0.8,0.7,1.9,1.1,3.4,1.1h18.4c1.5,0,2.6-0.4,3.4-1.1
                    c0.8-0.7,1.2-1.8,1.2-3.1c0-1.4-0.4-2.5-1.2-3.2c-0.8-0.7-1.9-1.1-3.4-1.1h-18.4c-1.5,0-2.6,0.4-3.4,1.1
                    C162.2,1.9,161.8,2.9,161.8,4.3"/>
                <path className="fill-secondary" d="M141.8,23c0,1.3,0.4,2.4,1.2,3.1c0.8,0.7,1.9,1.1,3.4,1.1h58.4c1.5,0,2.6-0.4,3.4-1.1c0.8-0.7,1.2-1.8,1.2-3.1
                    c0-1.4-0.4-2.5-1.2-3.2c-0.8-0.7-1.9-1.1-3.4-1.1h-58.4c-1.5,0-2.6,0.4-3.4,1.1C142.2,20.6,141.8,21.7,141.8,23"/>
            </g>
            </svg>

        </div>
        
    )
    
}
export default LoaderStart;