import Swal from 'sweetalert2'

/************************/
/* FONCTION UTILITAIRES */
/************************/

//Convertisseur de date
export const frenchDateConverter = (customDate = null) => {
    // Si une date est fourni, on la transforme en date standard
    const date = customDate ? new Date(customDate) : new Date();

    // Vérification si la date est valide
    if (isNaN(date.getTime())) {
        console.error("Date invalide");
        return null;
    }

    //Tranformation au format francais
    const jour = ('0' + date.getDate()).slice(-2);
    const mois = ('0' + (date.getMonth() + 1)).slice(-2);
    const annee = date.getFullYear().toString();  
    const heures = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const secondes = ('0' + date.getSeconds()).slice(-2);

    const dateFormatee = `${jour}/${mois}/${annee} ${heures}:${minutes}:${secondes}`;
    const dateFormateeShort = `${jour}/${mois}/${annee}`;

    let dateFormateeDatas = {
        'fullDate': dateFormatee,
        'shortDate': dateFormateeShort,
        'encodeDate': encodeURIComponent(dateFormatee)
    }
    return dateFormateeDatas;

};

//Verifie que l'utilisateur est bien connecté
export const verifAthentification = () => {
    
    const cookie = document.cookie;
    // Recherche du token d'authentification dans le cookie
    const token = cookie.split(';').find(c => c.trim().startsWith(`auth`));
    // Extrait la valeur du token d'authentification
    const tokenValue = token ? token.split('=')[1] : null;
    //Récupération de l'id de l'utilisateur courant
    const currentUserId = JSON.parse(localStorage.getItem("userData"))?.userId;

    if (tokenValue) {
        return { token: tokenValue, userId: currentUserId };
    }

    

    return { token: null, userId: null };

};


//Settigs des alertes
export const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false 
});
