import { 
    RecupCommsTop,
    getUser,
    saveComm,
} from '../utils/utilsAPI';
import { 
    Toast,
    frenchDateConverter
} from '../utils/utils';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useUser } from '../UserContext';


function Commentaires({ IDTop }) {

    //Mise en place de la gestion du form avec useForm
    const {register, handleSubmit, reset, formState: {errors}} = useForm();
    //On récupère l'iD du user connecté
    const {user} = useUser();
    // Stockage all infos
    const [allInfos, setAllInfos] = useState([]);
    

    useEffect(() => {

        setAllInfos([]);

        // Récupèration des commentaires 
        RecupCommsTop(IDTop)
        .then((resultComms) => {

            console.log("tous les comms", resultComms.data.commentaires);
            console.log("id top ", IDTop);
            

            resultComms.data.commentaires.forEach((comm) => {

                //Récupèration des infos de l'utilisateur du ccommentaire 
                getUser(comm.IDUser)
                    .then((resultCommUser) => {

                            const dateFormatee = frenchDateConverter(comm.date);
        
                            const RecupAllInfos = {
                                userId: resultCommUser._id, 
                                commId: comm._id,
                                avatar: resultCommUser.avatar,
                                pseudo: resultCommUser.pseudo,
                                tagName: resultCommUser.tagName,
                                date: dateFormatee.shortDate,
                                comm: comm.commentaire 
                            }
                            
                            setAllInfos(allInfos => [...allInfos, RecupAllInfos]);

                    })    
                    .catch((error) => {
                        console.error("Erreur lors de l'appel à RecupInfosTop :", error);
                    });

            });         
    
        })    
        .catch((error) => {
            console.error("Erreur lors de l'appel à RecupInfosTop :", error);
        });

    }, [IDTop]);
    

    const onSubmit = function(data) {

        //Informations du nouveau top a enregistrer
        const NewComment = {
            commentaire: data.commentaire,
            IDTop: IDTop,
            IDUser: user._id
        };

        //Sauvegarde du nouveau commentaire
        saveComm(NewComment, IDTop )
            .then((response) => {

                //Mise a jour des nouveaux commentaires en local
                const dateFormatee = frenchDateConverter(new Date())
                const newRecupAllInfos = {
                    userId: response.data.user.userId, 
                    commId: response.data.commId,
                    avatar: user.avatar,
                    pseudo: user.pseudo,
                    tagName: user.tagName,
                    date: dateFormatee.shortDate,
                    comm: data.commentaire 
                }

                setAllInfos(prevState => [...prevState, newRecupAllInfos]);

                Toast.fire({
                    icon: 'success',
                    title: 'Le nouveau commentaire a été ajouté'
                });
                reset();
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: "La sauvegarde du commentaire n'a pas fonctionné"
                });
                console.error(error);
            });
          
    };
    
    return(
        <>
        
            {user && user.authenticated &&
                
                <form onSubmit={handleSubmit(onSubmit)} className='w-4/5 flex flex-wrap flex-col m-auto rounded-lg text-left justify-start pb-20'>  

                    <div className='mt-5'>
                        <label htmlFor="chip" className="block text-sm text-tertiary-300 font-bold text-left">
                            Ajoute ton commentaire
                        </label>
                        <input
                            type="text"
                            name="commentaire"
                            id="commentaire"
                            autoComplete='off'
                            className="text-primary border-b border-tertiary-300 w-full focus:outline-none font-bold"
                            {...register("commentaire", { required: "Il faut remplir le commentaire" })} 
                        />
                        <p className="text-red-500">{errors.commentaire && errors.commentaire.message}</p>
                    </div>

                    <div className='mt-8 flex flex-col items-center'>
                        <button type="submit" id="buttonSubmit" className="bg-primary text-tertiary-100 rounded-full px-6 py-2 mb-5 w-full">
                            ENREGISTRER
                        </button>
                    </div>

                </form>
            }

            <div className='flex flex-wrap justify-center mb-16'>
                                
                {allInfos
                    .sort((a, b) => {
                        // Fonction pour convertir "DD/MM/YYYY" en objet Date
                        const convertToDate = (dateStr) => {
                            const [day, month, year] = dateStr.split('/');
                            return new Date(`${year}-${month}-${day}`);
                        };
                        // Comparer les dates après conversion
                        return convertToDate(b.date) - convertToDate(a.date);
                    })
                    .map((commentaire, index) => (

                        <div key={`${commentaire.targetTop}-${index}`} className="w-4/5 border-2 border-secondary flex flex-wrap p-2.5 my-2 rounded-lg text-left justify-start">
                            <div>
                                <NavLink to={`/profil/${commentaire.tagName}`}>
                                    <div className="flex">
                                        <div>
                                            <img className="inline-block h-12 w-12 mr-2 rounded-full ring-2 ring-white" src={`${commentaire.avatar}`} alt={`/${commentaire.pseudo}`}/>
                                        </div>
                                        <div>
                                            <p className="font-semi">{commentaire.pseudo}
                                                <span className="font-normal text-tertiary-300"> @{commentaire.tagName} / {commentaire.date}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <p>{commentaire.comm}</p>
                                </NavLink>
                            </div>
                        </div>
                ))}
                
            </div>
        </>
    )
}
export default Commentaires;