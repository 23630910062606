
import Commentaires from "../Components/Commentaires";

import { useParams } from "react-router";
import AffichageTops from '../Components/AffichageTops';

import { 
    getTop,
} from '../utils/utilsAPI';
import { useState } from "react";

function Top() {

    const { topId } = useParams();
    const [remixTopState, setRemixTopState] = useState();

    // Récupération des données du top
    const getAllTopsDatas = async () => {
        try {
            const topsData = await getTop(topId);                
            setRemixTopState(topsData.remixTop);
            
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };
    getAllTopsDatas();

    return(
        <>
            <div id="home" className="flex flex-wrap justify-center mb-16 pt-20 px-6">
                <AffichageTops IDTop={topId}/>
            </div> 
            <Commentaires IDTop={topId}/>
            
        </>
    )
}

export default Top;