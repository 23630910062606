import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';


const FavDayStats = ({ croppedImages, getDateFormatted, titre, choix }) => {

  // Utilisation de useEffect pour gérer les changements de choix
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    if (choix && Array.isArray(choix) && choix.length > 0) {
      // Filtrer les choix valides
      const validChoices = choix.filter(item => item.id && item.content);

      // Calculer le total des compteurs
      const totalCompteurs = validChoices.reduce((acc, item) => acc + item.compteur, 0);

      // Calculer les pourcentages pour chaque élément
      const choixAvecPourcentages = validChoices.map(item => {
        return {
          ...item,
          pourcentage: Math.round((item.compteur / totalCompteurs) * 100)
        };
      });

      // Corriger la somme des pourcentages si elle dépasse 100
      const sommePourcentages = choixAvecPourcentages.reduce((acc, item) => acc + item.pourcentage, 0);
      const difference = 100 - sommePourcentages;

      // Ajuster la différence (ajoutée au premier élément ou autre, selon la logique)
      if (difference !== 0) {
        choixAvecPourcentages[0].pourcentage += difference;
      }
      //Faire le tri
      const choixTries = choixAvecPourcentages.sort((a, b) => b.pourcentage - a.pourcentage);


      // Mettre à jour les éléments avec les pourcentages calculés
      setItems(choixTries);
    }
  }, [choix]);


  return(
    <div className='relative'>
      
      <div style={{ backgroundImage: `url(${croppedImages.mediumImg})` }} className='bg-cover relative rounded-2xl mb-10 p-8 px-14 z-0 h-[530px] w-[500px]' >
        <div className="absolute top-0 left-0 w-full h-full bg-purple-500 opacity-50 z-[1] rounded-2xl"></div>

        <div className='relative z-20'>
          {/* Header  */}
          <div className='flex flex-row items-center justify-start text-secondary mb-8'>
              <svg version="1.1" id="linkFavday" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48.3 40.8" strokeWidth="2.5" className="w-14 h-14" fill="none">
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="1" y1="13.2" x2="47.3" y2="13.2"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="35" y1="7.2" x2="35" y2="1"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="13.3" y1="1" x2="13.3" y2="7.2"></line>
                  <path className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M39.3,39.8h2.1c3.2,0,5.9-2.6,5.9-5.9V10c0-3.2-2.6-5.9-5.9-5.9H6.9C3.6,4.1,1,6.8,1,10v23.9 c0,3.2,2.6,5.9,5.9,5.9h23"></path>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="40.1" y1="31.8" x2="8.1" y2="31.8"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="33.8" y1="25.9" x2="14.5" y2="25.9"></line>
                  <line className="stroke-secondary" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="27.5" y1="20" x2="20.8" y2="20"></line>
              </svg>
              <div className='flex flex-col pl-4'>
                  <h2 className='text-xl'>fav of the day {getDateFormatted}</h2>
                  <h3 className='text-2xl'>{ titre }</h3>
              </div>
          </div>

          {/* Liste des choix  */}
          {items.map((item, index) => (

            <div key={item.id} className='flex flex-row items-center text-xl mb-4'>
              <span className='w-[8%] text-secondary text-4xl font-bold'>{index + 1}&nbsp;&nbsp;</span>
              <div className='w-full'>
                <p className='list-disc text-white text-lg'>{item.content}</p>
                <div className='flex flex-row items-center'>
                  <div className="bg-secondary h-6 rounded-full mr-4" style={{ width: `${item.pourcentage}%` }}></div>
                  <p className='text-white text-2xl'>{item.pourcentage}%</p>
                </div>
              </div>
            </div>
            
          ))}      
        </div>
      </div>
    </div>
  )

  
};

export default FavDayStats;
