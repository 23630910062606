import { useState } from "react";
import { WhatsappShareButton, EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappIcon, EmailIcon, FacebookIcon, TwitterIcon } from 'react-share'; 


function GestionShare({topDatas}) {

    // Stockage tops
    const [isMenuVisible, setIsMenuVisible] = useState(false); 


    const toggleMenu = () => {
        setIsMenuVisible(prevState => !prevState);
    };

    var shareUrl = window.location.href;
    if (!shareUrl.includes('top')) {
        shareUrl = window.location.href + `top/${topDatas._id}`;
    }


    return( 
        <>
            <div onClick={toggleMenu} className="cursor-pointer pr-4">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 35.9 35.9" fill="none" strokeWidth={1.5} stroke={` ${ topDatas.favday ? '#ffffff' : '#1D1D1B' }`}>
                    <g>
                        <ellipse transform="matrix(0.5257 -0.8507 0.8507 0.5257 8.7697 28.1674)" cx="29.6" cy="6.2" rx="5.5" ry="5.5"/>
                        <ellipse transform="matrix(0.5257 -0.8507 0.8507 0.5257 -11.176 39.2879)" cx="29.6" cy="29.7" rx="5.5" ry="5.5"/>
                        <circle cx="6.2" cy="18" r="5.5"/>
                        <line x1="15.4" y1="13" x2="19.9" y2="10.7"/>
                        <line x1="15.4" y1="22.8" x2="19.9" y2="25.1"/>
                    </g>
                </svg>
            </div>
            {/* Si le menu est visible, afficher le menu de partage */}
            {isMenuVisible && (
                <>
                    <div className="fixed w-full h-full z-50 bottom-0 left-0 bg-black bg-opacity-50 flex items-end justify-center" onClick={() => setIsMenuVisible(false)} >
                        <div className="shadow-lg w-full bg-black mt-2 p-4 flex flex-row items-center justify-center space-x-4">
                            {/* Boutons de partage */}
                            <WhatsappShareButton url={shareUrl} title={topDatas.titre}>
                                <WhatsappIcon size={45} round={true} />
                            </WhatsappShareButton>

                            <EmailShareButton url={shareUrl} subject="Regarde ce top !" body="Voici un top intéressant que j'ai trouvé, regarde-le !">
                                <EmailIcon size={45} round={true} />
                            </EmailShareButton>

                            <FacebookShareButton url={shareUrl}>
                                <FacebookIcon size={45} round={true} />
                            </FacebookShareButton>

                            <TwitterShareButton url={shareUrl} title={topDatas.titre}>
                                <TwitterIcon size={45} round={true} />
                            </TwitterShareButton>
                        </div>
                    </div>
                </>
            )}
        </>
    )

    
    
}

export default GestionShare;