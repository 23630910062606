import axios from "axios";
import { useEffect, useState } from "react";

import { useUser } from '../UserContext';

function GestionLike({ currentTopId, currentUserId, favDay }) {

    // Stockage tops
    const [likes, setLikes] = useState({ compteur: 0, users: [] });
    const [liked, setLiked] = useState(false);

    const {user} = useUser();

    useEffect(() => {       

        axios({ 
            method: 'get',
            url:   process.env.REACT_APP_BACKEND_URL + `/api/tops/${currentTopId}` 
        }).then((response) => {
            

            // cloner l'objet "data" pour éviter de modifier le state directement
            const newData = {...response.data[0].like};
            setLikes(newData);

            // Chercher l'utilisateur
            const index = newData.users.indexOf(currentUserId);

            //on initialise la couleur du like
            if (index !== -1) { 
                setLiked(true);
            } else { 
                setLiked(false);
            }
 
        }).catch((error) => { 
            console.error(error);
        });


    },[]);


    function gestionLike(){
        
        
        if (!currentUserId) return;

        

        // cloner l'objet "likes" pour éviter de modifier le state directement        
        const updatedLikes = { ...likes }; 
    
        // Chercher l'utilisateur
        const index = updatedLikes.users.indexOf(currentUserId);
        
        if (index !== -1) { // L'utilisateur est présent dans le tableau, le supprimer
            updatedLikes.users.splice(index, 1);
            updatedLikes.compteur--;
            setLiked(false);
        } else { // L'utilisateur n'est pas présent dans le tableau, l'ajouter
            updatedLikes.compteur++;
            updatedLikes.users.push(currentUserId);
            setLiked(true);
        }
        
        // Mettre à jour le state de "likes"
        setLikes(updatedLikes);

        // Et on enregistre en BDD
        axios({ 
            method: 'post',
            url:   process.env.REACT_APP_BACKEND_URL + `/api/topsupdatedlikes/${currentTopId}`,
            data: updatedLikes 
        }).then((response) => {

            console.log('Like updated');

        }).catch((error) => { 
            console.error(error);
        });   
        

    }


    return( 
        <>
            <div className="flex pr-4" onClick={gestionLike}>
                <svg 
                    version="1.1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    x="0px" y="0px" 
                    viewBox="0 0 40.5 35.8" 
                    fill="none" strokeWidth={1.5}  
                    className={`
                        cursor-pointer w-6 h-6
                        ${favDay ?
                            (liked ? 'stroke-white': 'stroke-white') :
                            (liked ? 'stroke-primary': 'stroke-tertiary-400')
                        }
                        `}
                >
                    <path d="M20.2,35l15.9-16.3c2.2-1.9,3.6-4.7,3.6-7.8c0-5.6-4.4-10.1-9.9-10.1c-4.7,0-8.6,3.4-9.6,7.9
                    c-1-4.5-4.9-7.8-9.6-7.8c-5.4,0-9.9,4.5-9.9,10.1c0,3.1,1.3,5.8,3.5,7.7l10.9,11.2"/>
                </svg>&nbsp;
                <span 
                    className={`
                        cursor-pointer text-sm
                        ${favDay ?
                            (liked ? 'text-white': 'text-white') :
                            (liked ? 'text-primary': 'text-tertiary-400')
                        }
                    `}
                >
                { likes.compteur }
                </span>
            </div>
        </>
    )
    
}

export default GestionLike;